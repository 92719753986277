import React from 'react';
import Button from '@material-ui/core/Button';
import { APIService } from '../../common/api.service';
import { useToasts } from 'react-toast-notifications';

const DownloadActivityFileButton = (props: any) => {
  const { addToast } = useToasts();
  const handleClick = async () => {
    if (!props.activityId) {
      addToast('No activity ID provided!', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    // fetch file from API
    const response = await APIService.downloadActivityFile(props.activityId);

    // serve reponse body as file download
    const blob = await response?.blob();
    if (!blob) {
      addToast('File not found!', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `activity_${props.activityId}.gpx`; // or any other file extension';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button variant="contained" size="small" color="primary" onClick={handleClick}>
      Download Track
    </Button>
  );
};

export default DownloadActivityFileButton;
