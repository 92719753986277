import React, { cloneElement } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useEditController,
  useListContext,
} from 'react-admin';
import { useToasts } from 'react-toast-notifications';

import { APIService } from '../../common/api.service';
import DownloadActivityFileButton from '../activities/download-activity-file-button';
import participationsExporter from './participations-exporter';

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};

const ParticipationShowActions = (props: any) => (
  <TopToolbar>
    <EditButton variant="contained" basePath={props.basePath} record={props.data} style={{ marginRight: '5px' }} />
    {props.permissions === 'superadmin' && props.data.activityId && <DownloadActivityFileButton activityId={props.data.activityId} />}
  </TopToolbar>
);

const SendBibButton = ({ record }: any) => {
  const { addToast } = useToasts();
  const sendBib = () => {
    APIService.sendBib(record.id).then((response) => {
      addToast('Bib Mail sent', { appearance: 'success', autoDismiss: true });
    });
  };
  return <Button variant="contained" color="primary" label="Send Bib" onClick={sendBib} />;
};

const SendCertificateButton = ({ record }: any) => {
  const { addToast } = useToasts();
  const sendCertificate = () => {
    APIService.sendCertificate(record.id).then((_response) => {
      addToast('Certificate Mail sent', {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  };
  return <Button variant="contained" color="primary" label="Send Certificate" onClick={sendCertificate} />;
};

const ParticipationFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Race Title" source="room.event_title||$contL" alwaysOn />
    <TextInput label="Race Id" source="room.id||$eq" alwaysOn />
    <TextInput label="Username" source="user.username||$contL" alwaysOn />
    <TextInput label="User ID" source="user.id||$eq" alwaysOn />
    <TextInput label="Code" source="code.code||$contL" alwaysOn />
    {props.permissions === 'superadmin' && <BooleanInput label="GPS Upload" source="userUpload||$eq||true" alwaysOn />}
    <TextInput label="ID+" source="id||$gt" />
    <TextInput label="ID-" source="id||$lt" />
    <BooleanInput label="Include external users " source="user.isExternalUser||$eq||false" />
  </Filter>
);

export const ParticipationList = (props: any) => (
  <List
    {...props}
    filters={<ParticipationFilter {...props} />}
    exporter={(participations: any) => participationsExporter(participations, props.permissions)}
    actions={<ListActions />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="time" />
      <TextField source="distance" />
      <DateField source="createdAt" />
      <TextField source="room.event_title" label="Race" />
      <TextField source="room.startDate" />
      <TextField source="room.endDate" />
      <TextField source="user.username" />
      {props.permissions === 'superadmin' && [
        <TextField source="room.raceType" />,
        <TextField source="code.code" label="Code" />,
        <BooleanField source="pinned" />,
      ]}

      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const ParticipationCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        perPage={50}
        sort={{ field: 'id', order: 'DESC' }}
        label="Race"
        source="room.id"
        reference="room"
        validate={[required()]}
        fullWidth
        filterToQuery={(s: string) => {
          if (s.length >= 1 && !isNaN(Number(s))) {
            return { 'id||$eq||': s };
          }
          return { 'event_title||$contL||': s.length > 2 ? s : 'Migros' };
        }}
      >
        <AutocompleteInput
          allowEmpty={true}
          optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.startDate}
          optionValue="id"
          helperText="Search by ID or Title"
        />
      </ReferenceInput>
      <NumberInput label="User ID" source="user.id" fullWidth />
      {props.permissions === 'superadmin' && <BooleanInput label="Ghostrunner" source="ghost" fullWidth />}
      <NumberInput source="time" fullWidth />
      <NumberInput source="distance" fullWidth />
    </SimpleForm>
  </Create>
);

const ParticipationEditTitle = ({ record }: any) => <span>{`${record.firstname} ${record.lastname}`}</span>;

export const ParticipationEdit = (props: any) => {
  const { record } = useEditController(props);

  return (
    <Edit {...props} title={<ParticipationEditTitle record />}>
      <SimpleForm redirect="list">
        <TextField disabled source="id" />
        <NumberInput label="Time [s]" source="time" />
        <NumberInput label="Distance [m]" source="distance" />

        {props.permissions === 'superadmin' && <BooleanInput label="Ghostrunner" source="ghost" fullWidth />}
        <DateField disabled source="createdAt" />
        <ReferenceInput perPage={200} sort={{ field: 'id', order: 'DESC' }} label="Race" source="room.id" reference="room" fullWidth>
          <SelectInput optionText={(r: any) => r.id + ' | ' + r.event_title + ' | ' + r.startDate} fullWidth />
        </ReferenceInput>
        <TextField disabled source="user.username" />
        <TextField disabled source="room.event_title" />
        <TextField disabled source="room.startDate" />
        <TextField disabled source="room.endDate" />
        {props.permissions === 'superadmin' && [
          <TextField disabled source="room.raceType" />,
          <BooleanInput source="pinned" />,
          <ReferenceInput
            perPage={1000}
            sort={{ field: 'id', order: 'DESC' }}
            filter={{ 'roomId||$eq': record?.roomId }}
            label="Code"
            source="code.id"
            reference="codes"
            fullWidth
            allowEmpty
          >
            <SelectInput optionText={(r: any) => r.id + ' | ' + r.code + ' | Used: ' + r.used} fullWidth />
          </ReferenceInput>,
        ]}
      </SimpleForm>
    </Edit>
  );
};

export const ParticipationShow = (props: any) => (
  <Show {...props} actions={<ParticipationShowActions {...props} />}>
    <TabbedShowLayout>
      <Tab label="Participation">
        <TextField source="id" />
        <TextField source="time" label="Time [s]" />
        <TextField source="distance" label="Distance [m]" />
        {props.permissions === 'superadmin' && <NumberField source="uphill" />}
        {props.permissions === 'superadmin' && <NumberField source="downhill" />}
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="user.username" />
        <TextField source="room.event_title" />
        <TextField source="room.startDate" />
        <TextField source="room.endDate" />
        <TextField source="room.raceType" />
        <TextField source="user.username" />
        <TextField source="code.code" />
        {props.permissions === 'superadmin' && <BooleanField source="pinned" />}
        {props.permissions === 'superadmin' && <BooleanField source="userUpload" />}
        {props.permissions === 'superadmin' && <TextField source="track.url" />}
      </Tab>
      {props.permissions === 'superadmin' && (
        <Tab label="Favorites">
          <ReferenceManyField pagination={<Pagination />} perPage={10} label="Favorites" reference="favorite" target="participationId">
            <Datagrid>
              <NumberField source="id" />
              <DateField source="createdAt" />
              <TextField source="favParticipation.user.username" />
              <TextField source="favParticipation.user.gender" />
              <TextField source="favParticipation.user.id" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      )}
      {props.permissions === 'superadmin' && (
        <Tab label="Mails">
          <SendBibButton />
          <br />
          <SendCertificateButton />
          <br />
        </Tab>
      )}
    </TabbedShowLayout>
  </Show>
);
